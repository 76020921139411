// @file Opens native share menu
import { trackEvent } from '@@/bits/analytics'
import { browserCan } from '@@/bits/browser'
import { captureException } from '@@/bits/error_tracker'
import { buildUrlFromPath } from '@@/bits/location'

export const openNativeShare = async (data: ShareData): Promise<void> => {
  const canUseNativeShare = browserCan('share')
  if (!canUseNativeShare) {
    captureException(new Error('Attempted to call share when not available'))
    return
  }

  try {
    await window.navigator.share(data)
  } catch (error: any) {
    // We don't capture AbortError when the user canceled the share operation or there are no share targets available
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#exceptions
    const ABORT_ERROR_CODE = 20
    if (error.code !== ABORT_ERROR_CODE) {
      captureException(error)
    }
  }
}

export type ShareService = 'facebook' | 'twitter' | 'email' | 'skoletube' | 'googleClassroom'
export const shareLinkOnService = (link: string, service: ShareService): void => {
  const openShareUrl: Function = window.open

  trackEvent(
    'Share Menu',
    {
      facebook: 'Clicked Facebook',
      twitter: 'Clicked Twitter',
      email: 'Clicked email',
      skoletube: 'Clicked Skoletube',
      googleClassroom: 'Clicked Google Classroom',
      zoom: 'Clicked Zoom',
    }[service],
  )

  switch (service) {
    case 'email':
      window.location.href = link
      break
    case 'googleClassroom':
      openShareUrl(link, service, 'width=640,height=400')
      break
    default:
      openShareUrl(link)
      break
  }
}

export interface BuildShareLinkParameters {
  service: ShareService
  url: string
  title: string
  description: string
  media: string
  publicKey: string
  params?: string // this is not used in email
}

export const buildShareLink = ({
  service,
  url,
  title,
  description,
  media,
  publicKey,
  params,
}: BuildShareLinkParameters): string => {
  switch (service) {
    case 'email':
      return `mailto:?${new URLSearchParams({
        subject: title,
        body: description,
      })
        .toString()
        .replace(/\+/g, '%20')}`
    case 'googleClassroom':
      return buildUrlFromPath(
        `/padlets/${publicKey}/shares/google_classroom${
          params !== undefined ? `?${new URLSearchParams(params).toString().replace(/\+/g, '%20')}` : ''
        }`,
      )
    default:
      return buildUrlFromPath(
        `/media/share/${service}?${new URLSearchParams({
          url,
          title,
          description,
          media,
          ...(params !== undefined ? { params } : {}),
        })
          .toString()
          .replace(/\+/g, '%20')}`,
      )
  }
}
